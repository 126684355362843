import { createRoot } from "react-dom/client"
import { useAppStore } from "./store/app/useAppStore";
import { useCallback, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import "./index.css";
import { useWidjetStore } from "./store/widjet/useWidjetStore";
import { getTimeInHotel } from "./modules/Widjet/api/getTimeInHotel";
import { useStore } from "zustand";
import { reviveObject } from "./utils/reviveObject";
import { getModuleIframe } from "./utils/getModuleIframe";
import useScreenSize from "./hooks/useScreenSize";

export function App() {
    const widjetRef = useRef();
    const calendarRef = useRef();
    const roomsRef = useRef();
    const widjetStore = useRef(useWidjetStore()).current;

    const { uploadSettings } = useWidjetStoreInitDataHook(widjetStore);

    const { calendarOpen, setCalendarOpen, roomsOpen, setRoomsOpen, moduleOpen, setModuleOpen } = useAppStore();

    const { calculateSizeCallback } = useCalculateSizeHook(widjetRef, calendarRef, roomsRef);

    useEffect(() => {
        function messageHandler(event) {
            if (event.data.isBookingModuleAction) {
                switch (event.data.action) {
                    case "closeCalendar":
                        setCalendarOpen(false);
                        break;
                    case "openCalendar":
                        setCalendarOpen(true);
                        postMessageIntoIframe(widjetRef, { action: "closeRooms" })
                        break;
                    case "openRooms":
                        setRoomsOpen(true);
                        postMessageIntoIframe(widjetRef, { action: "closeCalendar" })
                        break;
                    case "closeRooms":
                        setRoomsOpen(false);
                        break;
                    case "closeCalendarFromCalendar":
                        postMessageIntoIframe(widjetRef, { action: "closeCalendar" })
                        break;
                    case "closeRoomsFromRooms":
                        postMessageIntoIframe(widjetRef, { action: "closeRooms" })
                        break;
                    case "initCalendarStore":
                        postMessageIntoIframe(calendarRef, { action: "updateStore", data: JSON.parse(JSON.stringify({ ...widjetStore.getState() })) })
                        break;
                    case "updateStoreFromCalendar":
                        widjetStore.setState(reviveObject(event.data.data))
                        postMessageIntoIframe(widjetRef, { action: "updateStore", data: JSON.parse(JSON.stringify({ ...widjetStore.getState() })) })
                        break;
                    case "updateStoreFromWidjet":
                        widjetStore.setState(reviveObject(event.data.data))
                        postMessageIntoIframe(calendarRef, { action: "updateStore", data: JSON.parse(JSON.stringify({ ...widjetStore.getState() })) })
                        break;
                    case "updateStoreFromRooms":
                        widjetStore.setState(reviveObject(event.data.data))
                        postMessageIntoIframe(widjetRef, { action: "updateStore", data: JSON.parse(JSON.stringify({ ...widjetStore.getState() })) })
                        break;
                    case "resizeRoomsIframe":
                        roomsRef.current.style.width = roomsRef.current.contentWindow.innerWidth + "px";
                        roomsRef.current.style.height = roomsRef.current.contentWindow.document.body.scrollHeight + "px";
                        roomsRef.current.style.opacity = 1;
                        calculateSizeCallback();
                        break;
                    case "openModule":
                        setModuleOpen(true);
                        postMessageIntoIframe(widjetRef, { action: "closeCalendar" });
                        postMessageIntoIframe(widjetRef, { action: "closeRooms" });
                        break;
                    case "closeModule":
                        setModuleOpen(false);
                        break;
                    case "initFromWidjet":
                        postMessageIntoIframe(widjetRef, { action: "updateStore", data: JSON.parse(JSON.stringify({ ...widjetStore.getState() })) })
                        break;
                    default:
                        return;
                }
            }
        }

        window.addEventListener("message", messageHandler);

        return () => {
            window.removeEventListener("message", messageHandler);
        }
    }, [setCalendarOpen, widjetStore, setRoomsOpen, setModuleOpen, calculateSizeCallback]);

    const loading = useStore(widjetStore, state => state.loading);
    useEffect(() => {
        if (loading) {
            postMessageIntoIframe(widjetRef, { action: "updateStore", data: JSON.parse(JSON.stringify({ ...widjetStore.getState() })) })
        }
    }, [loading, widjetStore])

    useEffect(() => {
        if (calendarOpen || roomsOpen) {
            calculateSizeCallback();
        }
    }, [calendarOpen, roomsOpen, calculateSizeCallback]);

    useEffect(() => {
        function callback() {
            setCalendarOpen(false);
            setRoomsOpen(false);
            postMessageIntoIframe(widjetRef, { action: "closeRooms" });
            postMessageIntoIframe(widjetRef, { action: "closeCalendar" });
        }
        window.addEventListener("click", callback)
        return () => window.removeEventListener("click", callback);
    }, [setCalendarOpen, setRoomsOpen])

    useEffect(() => {
        if (moduleOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "";
        }
    }, [moduleOpen]);


    const dateFrom = useStore(widjetStore, state => state.dateFrom);
    const dateTo = useStore(widjetStore, state => state.dateTo);
    const rooms = useStore(widjetStore, state => state.rooms);
    const timeSettings = useStore(widjetStore, state => state.timeSettings);

    const [roomsMode, setRoomsMode] = useState(null);

    useEffect(() => {
        function resizeHandler() {
            if (!roomsOpen) return;
            if (window.innerWidth > 768) {
                setRoomsMode("modal");
            } else {
                setRoomsMode("fullscreen");
            }
        }

        window.addEventListener("resize", resizeHandler);

        resizeHandler();

        return () => window.removeEventListener("resize", resizeHandler);
    }, [roomsOpen])

    return <>
        {createPortal(<iframe ref={widjetRef} src={process.env.WIDJET_ORIGIN} title="booking-module-widjet" id="booking-module-widjet"></iframe>, document.body)}
        {calendarOpen && createPortal(<iframe ref={calendarRef} src={process.env.CALENDAR_ORIGIN} title="booking-module-calendar" id="booking-module-calendar"></iframe>, document.body)}
        {roomsOpen && roomsMode === "modal" && createPortal(<iframe ref={roomsRef} style={{ opacity: 0 }} src={process.env.ROOMS_ORIGIN} title="booking-module-rooms" id="booking-module-rooms"></iframe>, document.body)}
        {roomsOpen && roomsMode === "fullscreen" && createPortal(<div id="booking-module-rooms-wrapper"><iframe ref={roomsRef} style={{ opacity: 0 }} src={process.env.ROOMS_ORIGIN} title="booking-module-rooms" id="booking-module-rooms"></iframe></div>, document.body)}
        {moduleOpen && createPortal(getModuleIframe(process.env.MODULE_ORIGIN, dateFrom, dateTo, rooms, timeSettings), document.body)}
    </>
}

function useWidjetStoreInitDataHook(store) {
    const setTimeSettings = useStore(store, state => state.setTimeSettings);
    const updateBookingSettings = useStore(store, state => state.updateBookingSettings);
    const updateRoomsLimits = useStore(store, state => state.updateRoomsLimits);
    const dateFrom = useStore(store, state => state.dateFrom);
    const dateTo = useStore(store, state => state.dateTo);
    const setDateFrom = useStore(store, state => state.setDateFrom);
    const setDateTo = useStore(store, state => state.setDateTo);
    const setIsFailed = useStore(store, state => state.setIsFailed);
    const setLoading = useStore(store, state => state.setLoading);

    const uploadSettings = useCallback(() => {
        getTimeInHotel()
            .then(data => {
                const offsetDiff = new Date().getTimezoneOffset() + data.offset;
                const today = new Date(new Date().getTime() + (offsetDiff * 60 * 1000));
                setTimeSettings(data);

                let df = new Date(new Date(today).setHours(0, 0, 0, 0));
                let dt = new Date(new Date(new Date(today.getTime() + 86400000)).setHours(0, 0, 0, 0));

                if (!dateFrom || !dateTo) {
                    setDateFrom(df);
                    setDateTo(dt);

                } else if (df.getTime() > dateFrom.getTime() || dt.getTime() > dateTo.getTime()) {
                    setDateFrom(df);
                    setDateTo(dt);
                }
            })
            .then(() => updateBookingSettings())
            .then(() => updateRoomsLimits())
            .then(() => setLoading(true))
            .catch(() => {
                setIsFailed(true)
            })
    }, [setDateFrom, setDateTo, setTimeSettings, updateRoomsLimits, updateBookingSettings, setIsFailed]);

    useEffect(() => {
        uploadSettings();
    }, [uploadSettings]);

    return { uploadSettings };
}

function postMessageIntoIframe(ref, data) {
    const iframe = ref.current;
    if (!iframe) {
        // console.log("Iframe not found, action: " + data.action)
        return;
    }
    iframe.contentWindow.postMessage({
        "isBookingModuleAction": true,
        ...data
    }, process.env.APP_ORIGIN)
}

function useCalculateSizeHook(widjetRef, calendarRef, roomsRef) {
    function calculateCalendarPosition() {
        let widjet = widjetRef.current;
        let calendar = calendarRef.current;
        let rooms = roomsRef.current;

        if (!widjet) {
            return;
        }


        let widjetRect = widjet.getBoundingClientRect();

        if (calendar) {
            if (window.innerWidth > 768) {
                let calendarRect = calendar.getBoundingClientRect();

                let calendarAnchorLeftPosition = widjetRect.left + window.scrollX + (widjetRect.width / 2);

                let calendarLeftOffset = (calendarAnchorLeftPosition - (calendarRect.width / 2));
                calendarLeftOffset = Math.round(calendarLeftOffset > 0 ? calendarLeftOffset : 0);

                let calendarTopAnchorPosition = widjetRect.top + window.scrollY;

                let calendarTopOffset;
                let widjetTopCalendarHeightDiff = widjetRect.top - calendarRect.height;
                if (widjetTopCalendarHeightDiff > 16) {
                    calendarTopOffset = calendarTopAnchorPosition - calendarRect.height;
                } else if (widjetTopCalendarHeightDiff + calendarRect.height + widjetRect.height > 0) {
                    calendarTopOffset = calendarTopAnchorPosition - calendarRect.height - widjetTopCalendarHeightDiff + 16;
                } else {
                    calendarTopOffset = window.scrollY + widjetRect.bottom + 16;
                }

                calendar.style.left = `${calendarLeftOffset}px`;
                calendar.style.top = `${calendarTopOffset}px`;
            } else {
                calendar.style.left = 0;
                calendar.style.top = 0;
            }
        }

        if (rooms) {
            if (window.innerWidth > 768) {
                let roomsRect = rooms.getBoundingClientRect();

                let roomsAnchorLeftPosition = widjetRect.left + window.scrollX + (widjetRect.width / 4 * 3);

                let roomsLeftOffset = (roomsAnchorLeftPosition - (roomsRect.width / 2));
                roomsLeftOffset = Math.round(roomsLeftOffset > 0 ? roomsLeftOffset : 0);

                let roomsTopAnchorPosition = widjetRect.top + window.scrollY;

                let roomsTopOffset;
                let widjetTopRoomsHeightDiff = widjetRect.top - roomsRect.height;
                if (widjetTopRoomsHeightDiff > 16) {
                    roomsTopOffset = roomsTopAnchorPosition - roomsRect.height;
                } else if (widjetTopRoomsHeightDiff + roomsRect.height + widjetRect.height > 0) {
                    roomsTopOffset = roomsTopAnchorPosition - roomsRect.height - widjetTopRoomsHeightDiff + 16;
                } else {
                    roomsTopOffset = window.scrollY + widjetRect.bottom + 16;
                }

                rooms.style.left = `${roomsLeftOffset}px`;
                rooms.style.top = `${roomsTopOffset}px`;
            } else {
                rooms.style.left = 0;
                rooms.style.top = 0;
            }

        }
    }

    const calculateCalendarPositionCallback = useCallback(calculateCalendarPosition, [calendarRef, widjetRef, roomsRef]);

    useEffect(() => {
        calculateCalendarPositionCallback();

        window.addEventListener("resize", calculateCalendarPositionCallback);
        window.addEventListener("scroll", calculateCalendarPositionCallback);

        return () => {
            window.removeEventListener("resize", calculateCalendarPositionCallback);
            window.removeEventListener("scroll", calculateCalendarPositionCallback);
        }
    }, [calculateCalendarPositionCallback]);

    return { calculateSizeCallback: calculateCalendarPosition }
}

const root = createRoot(document.getElementById("booking-module"));

root.render(<App />);